@import "~reset-css/sass/_reset.scss";
body {
  line-height: 1.1;
  font-family: 'Noto Sans KR', sans-serif;
}
a:link, a:visited, a:focus, a:hover, a:active {
  color: inherit;
}

.montserrat {
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 800;
}

.hero {
  background-color: #ffd12f;
  text-align: center;
  color: #3f0518;
  font-weight: 800;
  width: 100%;
  height: 640px;
  position: relative;

  h2 {

    font-size: 30px;
    z-index: 2;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 64px;
  }
  h1 {
    font-size: 44px;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 124px;
  }

  .app {
    width: 100%;
    position: absolute;
    top: 240px;

    img {
      width: 312px;
      margin: 0 auto;
    }
  }

  .shape1 {
    background-image: url("/images/hero-shape1.svg");
    width: 109px;
    height: 109px;
    position: absolute;
    top: 185px;
    left: 5%;
    z-index: 0;
  }
  .shape2 {
    background-image: url("/images/hero-shape2.svg");
    width: 223px;
    height: 183px;
    position: absolute;
    top: 308px;
    right: 0;
  }
}

section.introduce {
  padding: 100px 32px 40px 32px;
  text-align: center;
  color: #414a53;

  .group {
    margin-top: 80px;

    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .desc {
      color: #6d747e;
      line-height: 1.4;
      margin-top: 16px;
    }
    img {
      max-width: 240px;
      margin: 40px auto 0;
    }
    img.swipe {
      margin-left: 20px;
    }
  }
}

section.i-wanna-buy {
  padding: 68px 0;
  text-align: center;
  background: url("/images/pink-cloud.png");
  background-size: cover;

  .title {
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
  }
  .desc {
    color: #ffdcde;
    margin-top: 32px;
    line-height: 24px;
    font-weight: bold;
  }

  img {
    width: 166px;
    margin-top: 24px;
  }
}

section.app-down {
  padding: 48px 32px;
  text-align: center;
  color: #3f0518;
  background-color: #f4f4f4;

  p {
    font-size: 18px;
    line-height: 1.6;
  }

  .ios {
    font-size: 14px;
    margin-top: 16px;
  }
}

.footer {
  padding: 32px;
  font-size: 14px;
  color: #9aa3ae;

  ul {
    margin-top: 16px;
    li {
      line-height: 1.6;
    }
  }
  .montserrat {
    font-size: 16px;
    color: #3f0518
  }
}